import { Theme } from 'typings/theme';
import { between, down, only, up } from 'utils/breakpoints';

const SPACING_FACTOR = 4;

const lightTheme: Theme = {
  breakpoints: {
    values: {
      mobile: 0,
      smallTablet: 600,
      largeTablet: 905,
      laptop: 1240,
      desktop: 1440,
      largeScreen: 1920,
      xs: 0,
      sm: 600,
      md: 905,
      lg: 1240,
      xl: 1440,
      xxl: 1920
    },
    up: up,
    down: down,
    between: between,
    only: only
  },
  shadow: {
    outerShadowLarge: '0px 8px 24px rgba(0, 0, 0, 0.16)',
    outerShadowMedium: '0px 8px 24px rgba(0, 0, 0, 0.12)',
    outerShadowSmall: '0px 8px 24px rgba(0, 0, 0, 0.08)',
    innerShadowLarge: 'inset 8px 8px 24px rgba(50, 50, 50, 0.06)',
    innerShadowSmall: 'inset 2px 2px 8px rgba(50, 50, 50, 0.08)',
    innerShadcnShadow: 'inset 0px 0px 4px rgba(0, 0, 0, 0.19)',
    shadowCn: '0px 0px 4px 0px #E7E7EA'
  },
  palette: {
    background: {
      default: '#F5F6F8'
    },
    primary: {
      main: '#007BFF',
      contrastText: '#000000'
    },
    common: {
      strongGreen: '#5DDC49',
      strongBlue: '#007BFF',
      strongYellow: '#F2E347',
      strongRed: '#FE694F',

      blueLinear: 'linear-gradient(137.52deg, #429DFF 0%, #007BFF 100%)',
      spotLinear: 'linear-gradient(142deg, #429DFF -41.33%, #5C59FD 62.55%, #755FFA 136.75%)',
      redLinear: 'linear-gradient(135deg, rgb(254, 105, 79) 0%, rgb(248, 35, 45) 100%)',

      purple: '#755FFA',
      blurple: '#5C59FD',
      leafyGreen: '#0DD782',

      black: '#3B3B3B',
      lightBlack: '#4A4A4A',

      grey100: '#323232',
      grey70: '#707070',
      grey40: '#B5B6B8',
      grey30: '#D5D6D8',
      grey10: '#E5E6E8',
      grey07: '#F0F1F4',
      grey05: '#F5F6F8',
      grey02: '#FAFBFD',
      borderGrey: '#E7E7EA',
      white: '#FFFFFF'
    },
    error: {
      main: '#d32f2f'
    }
  },
  spacing: (value: number | string) =>
    typeof value === 'string' ? value : `${value * SPACING_FACTOR}px`,

  typography: {
    primary: {
      fontFamily: `${'Nunito'}, sans-serif`
    },
    secondary: {
      fontFamily: `${'Nunito Sans'}, sans-serif`
    },
    weight: {
      light: 300,
      regular: 400,
      medium: 500,
      bold: 600
    },

    h1: {
      fontFamily: `${'Nunito'}, sans-serif`,
      fontSize: '56px',
      fontWeight: 600,
      lineHeight: 1.5,
      mobile: {
        fontSize: '36px'
      }
    },
    h2: {
      fontFamily: `${'Nunito'}, sans-serif`,
      fontSize: '40px',
      fontWeight: 600,
      lineHeight: 1.5,
      mobile: {
        fontSize: '26px'
      }
    },
    h3: {
      fontFamily: `${'Nunito'}, sans-serif`,
      fontSize: '26px',
      fontWeight: 600,
      lineHeight: 1.5,
      mobile: {
        fontSize: '20px'
      }
    },
    h4: {
      fontFamily: `${'Nunito Sans'}, sans-serif`,
      fontSize: '32px',
      fontWeight: 700,
      lineHeight: 1.5,
      mobile: {
        fontSize: '20px'
      }
    },
    h5: {
      fontFamily: `${'Nunito Sans'}, sans-serif`,
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: 1.5,
      mobile: {
        fontSize: '18px'
      }
    },
    subtitle1: {
      fontFamily: `${'Nunito Sans'}, sans-serif`,
      fontSize: '18px',
      fontWeight: 700,
      lineHeight: 1.5,
      mobile: {
        fontSize: '16px'
      }
    },
    subtitle2: {
      fontFamily: `${'Nunito Sans'}, sans-serif`,
      fontSize: '16px',
      fontWeight: 700,
      lineHeight: 1.5,
      mobile: {
        fontSize: '14px'
      }
    },
    subtitle3: {
      fontFamily: `${'Nunito Sans'}, sans-serif`,
      fontSize: '14px',
      fontWeight: 700,
      lineHeight: 1.5,
      mobile: {
        fontSize: '12px'
      }
    },
    body1: {
      fontFamily: `${'Nunito Sans'}, sans-serif`,
      fontSize: '18px',
      fontWeight: 400,
      lineHeight: 1.5,
      mobile: {
        fontSize: '16px'
      }
    },
    body2: {
      fontFamily: `${'Nunito Sans'}, sans-serif`,
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: 1.5,
      mobile: {
        fontSize: '14px'
      }
    },
    body3: {
      fontFamily: `${'Nunito Sans'}, sans-serif`,
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.5,
      mobile: {
        fontSize: '12px'
      }
    },
    detailRegular: {
      fontFamily: `${'Nunito Sans'}, sans-serif`,
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: 1.5,
      mobile: {
        fontSize: '12px'
      }
    },
    detailBold: {
      fontFamily: `${'Nunito Sans'}, sans-serif`,
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: 1.5,
      mobile: {
        fontSize: '12px'
      }
    },
    petitRegular: {
      fontFamily: `${'Nunito Sans'}, sans-serif`,
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: 1.5,
      mobile: {
        fontSize: '10px'
      }
    },
    petitBold: {
      fontFamily: `${'Nunito Sans'}, sans-serif`,
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: 1.5,
      mobile: {
        fontSize: '10px'
      }
    }
  }
};

export default lightTheme;
