import React, { createContext, useMemo, useState } from 'react';

export interface ReturnContextValues {
  setReturn: (state: boolean) => void;
  canReturn: boolean;
}

export const ReturnContext = createContext<ReturnContextValues | undefined>(undefined);

export function useReturnContext() {
  const context = React.useContext(ReturnContext);

  if (context === undefined) {
    throw new Error('useReturnContext must be used within a provider');
  }

  return context;
}

export const ReturnProvider = (props: { children: React.ReactNode }) => {
  const { children } = props;
  const [canReturn, setReturn] = useState<boolean>(false);

  const contextValue = useMemo(
    () => ({
      setReturn,
      canReturn
    }),
    [canReturn, setReturn]
  );

  return <ReturnContext.Provider value={contextValue}>{children}</ReturnContext.Provider>;
};

export default ReturnContext;
