import { createGlobalStyle } from 'styled-components';
import { resetCSS } from 'utils/reset-css';

export const GlobalStyles = createGlobalStyle`
  ${resetCSS}
  
  html, button {
    font-family: ${(props) => props.theme.typography.secondary.fontFamily};
    
  }

  body {
    margin: 0px;
    padding: 0;
    color: ${(props) => props.theme.palette.primary.contrastText};
    background-color: ${(props) => props.theme.palette.background.default};
    font-weight: ${(props) => props.theme.typography.weight.regular};
    font-size: 16px;
    min-height: 100vh;

    overflow-x: hidden;
  }

  div[id="root"] {
    min-height: 100vh;
  }

  button {
    margin: 0;
    padding: 0;
  }
`;

export default GlobalStyles;
