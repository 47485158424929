import { useAuth0 } from '@auth0/auth0-react';
import axios, { AxiosInstance } from 'axios';
import React from 'react';

const BFF_BASE_URL = process.env.REACT_APP_BFF_BASE_URL;

if (!BFF_BASE_URL) {
  throw new Error('BFF_BASE_URL is not defined');
}

export interface BFFContextProps {
  children: React.ReactNode | React.ReactNode[];
}

export interface BFFContextValues extends AxiosInstance {}

export const BFFContext = React.createContext({} as BFFContextValues);

export const BFFProvider = (props: BFFContextProps): JSX.Element => {
  const { children } = props;
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();

  const service = React.useMemo(() => {
    const service = axios.create({
      headers: {
        'Content-Type': 'application/json'
      },
      baseURL: `${BFF_BASE_URL}`
    });

    service.interceptors.request.use(
      async (config) => {
        let token;
        try {
          token = await getAccessTokenSilently();
        } catch (e: any) {
          if (e.error === 'consent_required') {
            token = await getAccessTokenWithPopup();
          } else {
            throw e;
          }
        }

        if (token) {
          config.headers.Authorization = `Bearer ${token}`;
        }

        // Prints the token during development, for use in testing
        if (process.env.NODE_ENV == 'development') {
          console.log('Authorization', config.headers.Authorization);
        }

        return config;
      },
      (error) => {
        // Do something with request error
        console.error('error', error);
        return Promise.reject(error);
      }
    );

    return service;
  }, [getAccessTokenSilently, getAccessTokenWithPopup]);

  return <BFFContext.Provider value={service}>{children}</BFFContext.Provider>;
};

export default BFFContext;
