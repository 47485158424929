import React from 'react';

import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

if (
  !process.env.REACT_APP_AUTH_DOMAIN ||
  !process.env.REACT_APP_AUTH_CLIENT_ID ||
  !process.env.REACT_APP_BFF_AUDIENCE
) {
  throw new Error('Missing Auth0 configuration');
}

interface Props {
  children?: React.ReactNode | React.ReactNode[];
}

const Auth0ProviderWithHistory: React.FC<Props> = (props) => {
  const { children } = props;
  const navigate = useNavigate();

  // const onRedirectCallback = (appState?: AppState) => {
  //   navigate(appState?.returnTo || window.location.pathname);
  // };

  // const onRedirectCallback = (appState?: AppState) => {
  //   navigate(appState?.returnTo || '/', { replace: true });
  // };

  const onRedirectCallback = (appState: any) => {
    // history.replace(appState && appState.returnTo ? appState.returnTo : window.location.pathname);
    // return window.location.origin;
    console.log('App State:', appState);
  };

  console.log(process.env.REACT_APP_AUTH_DOMAIN, process.env.REACT_APP_AUTH_CLIENT_ID);

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH_DOMAIN || ''}
      clientId={process.env.REACT_APP_AUTH_CLIENT_ID || ''}
      redirectUri={window.location.origin}
      audience={process.env.REACT_APP_BFF_AUDIENCE || ''}
      // onRedirectCallback={onRedirectCallback}
      // scope="openid profile email"
      // useRefreshTokens={true}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
